import { GsapContextProvider } from "gsapContext";
import { useRoutes } from "react-router-dom";
import GlobalStyle from "styles/GlobalStyles";
import ThemeProvider from "styles/ThemeProvider";

import Layout from "components/composite/Layout";
import GlobalAnimations from "GlobalAnimations";
import Blog from "pages/Blog";
import Careers from "pages/Careers/CareersPage";
import ComingSoon from "pages/ComingSoon";
import EnergyBuyerSolutionPage from "pages/solution/energy-buyer/EnergyBuyerSolutionPage";
import DataCentreSolutionPage from "pages/solution/data-centre/DataCentreSolutionPage";
import EPCSolutionPage from "pages/solution/epc/EPCSolutionPage";
import HomePage from "pages/home/HomePage";
import LandlordSolutionPage from "pages/solution/landlord/LandlordSolutionPage";
import NotFound from "pages/NotFound";
import ProductPage from "pages/product/ProductPage";
import RequestDemoPage from "pages/request-a-demo/RequestDemoPage";
import ContactUsPage from "pages/contact-us/ContactUsPage";
import SolutionPagesLayout from "pages/solution/SolutionPagesLayout";
import SubscriptionConfirmation from "pages/SubscriptionConfirmation";
import ContactConfirmation from "pages/ContactConfirmation";
import { paths } from "constants";
import PrivacyPolicyPage from "pages/privacy/PrivacyPolicyPage";
import AnalysePage from "pages/Analyse";

const App = () => {
  const Router = () => {
    const router = useRoutes([
      {
        path: paths.home,
        element: <Layout />,
        children: [
          { index: true, element: <HomePage /> },
          { path: paths.product.slice(1), element: <ProductPage /> },
          { path: paths.requestDemo.slice(1), element: <RequestDemoPage /> },
          { path: paths.contactUs.slice(1), element: <ContactUsPage />},
          {
            path: paths.solution.slice(1),
            element: <SolutionPagesLayout />,
            children: [
              { index: true, element: <LandlordSolutionPage /> },
              {
                path: paths.landlord.slice(1),
                element: <LandlordSolutionPage />,
              },
              {
                path: paths.energyBuyer.slice(1),
                element: <EnergyBuyerSolutionPage />,
              },
              {
                path: paths.dataCentre.slice(1),
                element: <DataCentreSolutionPage />,
              },
              {
                path: paths.epc.slice(1),
                element: <EPCSolutionPage />,
              }
            ],
          },
          {
            path: `${paths.analyse.slice(1)}/:latitude/:longitude`,
            element: <AnalysePage />,
          },
          { path: paths.blog.slice(1), element: <Blog /> },
          { path: paths.career.slice(1), element: <Careers /> },
          { path: paths.login.slice(1), element: <ComingSoon /> },
          {
            path: paths.subscriptionConfirmation.slice(1),
            element: <SubscriptionConfirmation />,
          },
          {
            path: paths.contactConfirmation.slice(1),
            element: <ContactConfirmation />,
          },
          { path: paths.privacy.slice(1), element: <PrivacyPolicyPage /> },
          { path: "*", element: <NotFound /> },
        ],
      },
    ]);
    return router;
  };

  return (
    <ThemeProvider>
      <GlobalStyle />
      <GsapContextProvider>
        <GlobalAnimations>
          <Router />
        </GlobalAnimations>
      </GsapContextProvider>
    </ThemeProvider>
  );
};

export default App;
