export const paths = {
  home: "/",
  product: "/product",
  analyse: "/analyse",
  requestDemo: "/request-demo",
  solution: "/solution",
  landlord: "/landlord",
  landlordComplete: "/solution/landlord",
  energyBuyer: "/energy-buyer",
  energyBuyerComplete: "/solution/energy-buyer",
  dataCentre: "/data-centre",
  dataCentreComplete:"/solution/data-centre",
  epc: "/epc",
  epcComplete:"/solution/epc",
  blog: "/blog",
  career: "/career",
  login: "https://app.inrange.io/",
  subscriptionConfirmation: "/subscription-confirmation",
  contactConfirmation: "/contact-confirmation",
  terms: "/terms-and-conditions",
  privacy: "/privacy-policy",
  contactUs: "/contact-us",
};
